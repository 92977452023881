body {
  margin: 0;
  font-family: "helveticaneuelight" !important;
  color: #00375f !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
  margin: 0;
}

.loader-container {
  position: absolute;
  width: 100%;
  height: 100%;

}

.custom-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #00375f);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}

.custom-loader.form {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #00375f);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  to {
    transform: rotate(1turn)
  }
}

.loader-forms {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(52, 61, 70, 0.8);
  z-index: 9999999999;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  border-width: 3px;
  border-style: solid;
  border-image: initial;
  border-radius: 50%;
  animation: 1.2s cubic-bezier(0.5, 0, 0.5, 1) 0s infinite normal none running lds-ring;
  border-color: white transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


strong {
  font-family: "helveticaneuebold";
}

em {
  font-family: "helveticaneueitalic";
}

a {
  text-decoration: none !important;
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.surveys-wrapper {
  opacity: 0;
  transition: 0.5s;
}

.row.gallery {
  opacity: 0;
  transition: 0.5s;
  margin-bottom: 40px;
}

.success-message {
  opacity: 0;
  transition: 0.5s;
}

.success-message.show {
  opacity: 1;
}

.surveys-wrapper.show {
  opacity: 1;
}

.row.gallery.show {
  opacity: 1;
}

[animate] {
  transition: transform 1s 0.5s, opacity 1s 0.5s;
  opacity: 0;
}

[animate].show {
  opacity: 1;
}

[animate="left"] {
  transform: translateX(-15px);
}

[animate="left"].show {
  transform: translateX(0);
}

[animate="right"] {
  transform: translateX(15px);
}

[animate="right"].show {
  transform: translateX(0);
}

[animate="up"] {
  transform: translateY(-15px);
}

[animate="up"].show {
  transform: translateY(0);
}

[animate="down"] {
  transform: translateY(15px);
}

[animate="down"].show {
  transform: translateY(0);
}

/* ::-webkit-scrollbar

{

 background: #00375f;

} */

.dropdown-shadow {
  box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.scrollable-container::-webkit-scrollbar {
  width: 7px;
}

.scrollable-container::-webkit-scrollbar-track {
  background: #fff;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #00375f;
  border-radius: 15px;
  transition: 0.5s all;
}

.sectors-dropdown::-webkit-scrollbar {
  width: 5px;
}

.sectors-dropdown::-webkit-scrollbar-track {
  background: #fff;
}

.sectors-dropdown::-webkit-scrollbar-thumb {
  background: #00375f;
  border-radius: 15px;
  transition: 0.5s all;
}

.subsectors-dropdown::-webkit-scrollbar {
  width: 5px;
}

.subsectors-dropdown::-webkit-scrollbar-track {
  background: #fff;
}

.subsectors-dropdown::-webkit-scrollbar-thumb {
  background: #00375f;
  border-radius: 15px;
  transition: 0.5s all;
}

.donors-dropdown::-webkit-scrollbar {
  width: 5px;
}

.donors-dropdown::-webkit-scrollbar-track {
  background: #fff;
}

.donors-dropdown::-webkit-scrollbar-thumb {
  background: #00375f;
  border-radius: 15px;
  transition: 0.5s all;
}

.years-dropdown::-webkit-scrollbar {
  width: 5px;
}

.years-dropdown::-webkit-scrollbar-track {
  background: #fff;
}

.years-dropdown::-webkit-scrollbar-thumb {
  background: #00375f;
  border-radius: 15px;
  transition: 0.5s all;
}

.privacy-title::-webkit-scrollbar {
  width: 7px;
}

.privacy-content h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 50px;
}

.privacy-title::-webkit-scrollbar-track {
  background: #fff;
}

.privacy-title::-webkit-scrollbar-thumb {
  background: #00375f;
  border-radius: 15px;
  transition: 0.5s all;
}

/* ::-webkit-scrollbar-track

{

 background: #00375f;

} */

.section-space {
  margin-top: 81px;
}

.shadow-custom {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.logo {
  padding: 18px 0;
}

.front-nav {
  z-index: 9999999;
  opacity: 1;
  transition: 0.5s;
  top: 0;
  width: 100%;
  left: 0;
  background-color: #fff;
}

.remove-opacity {
  opacity: 0;
}

/* .custom-box {
  margin: 0 64px;
} */

.menu-items {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-list {
  margin-bottom: 0;
}

.menu-list li {
  position: relative;
  display: inline-block;
  color: #00375f;
  padding-left: 5px;
  /* border-left: 3px solid transparent; */
  transition: 0.5s;
  font-size: 16px;
}

.menu-list li:before {
  content: "";
  position: absolute;
  left: -3px;
  height: 100%;
  border-left: 3px solid #e30613;
  opacity: 0;
  transition: 0.5s;
}

a.activePage li {
  color: red;
}

a.activePage li {
  border-left: 3px solid #e30613;
  transition: 0.5s;
}

p.single-sector-title.activePage {
  color: red;
  padding-left: 3px;
}

p.single-sector-title.activePage {
  border-left: 3px solid #e30613;
  transition: 0.5s;
}

@media (hover: hover) {
  .menu-list li:hover:before {
    opacity: 1;
  }

  .menu-list li:hover {
    color: #e30613;
    /* border-left-color: #e30613; */
  }

  .library:hover p {
    color: #e30613;
  }
}

.menu-list a:not(:last-child) {
  margin-right: 32px;
}

.sectors-list {
  width: 230px;
  padding: 30px 30px 10px 30px;
}

.sectors-list-container {
  left: 50%;
  transform: translateX(-42%);
  padding-top: 30px;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s;
  background-color: #fff;
}

.has-children-sectors p {
  color: #00375f;
  transition: 0.5s all;
}

.has-children-library p {
  color: #00375f;
  transition: 0.5s all;
}

.sub-sectors-title {
  font-family: "helveticaneuebold";
  font-size: 20px;
  text-align: center;
  padding-bottom: 30px;
}

.sub-sector-title h1 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.has-children-sectors p:hover {
  color: #e30613;
}

.has-children-sectors:hover .sectors-list-container {
  opacity: 1;
  pointer-events: all;
  color: #00375f;
}

/* .has-children-sectors.activePage {
  color: #e30613;
  border-left: 3px solid #e30613;
} */

.sector {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  transition: 0.5s;
}

.sector:hover {
  color: #e30613;
}

.sector:hover img {
  filter: invert(13%) sepia(88%) saturate(6711%) hue-rotate(352deg) brightness(88%) contrast(103%);
}

.sector p {
  margin-bottom: 0;
}

.library-list-container {
  transform: translateX(-50%);
  padding-top: 30px;
  opacity: 0;
  transition: 0.5s;
  pointer-events: none;
  background-color: #fff;
}

.library-list {
  width: 200px;
  padding: 30px 30px 10px 30px;
}

.library {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
  transition: 0.5s;
}

.arrow-right {
  height: 24px;
  width: 24px;
}

.has-children-library:hover .library-list-container {
  opacity: 1;
  pointer-events: all;
  color: #00375f;
}

.library:hover {
  color: #e30613;
}



.library:hover img {
  filter: invert(13%) sepia(88%) saturate(6711%) hue-rotate(352deg) brightness(88%) contrast(103%);
}

.library p {
  margin-bottom: 0;
}

.menu-button {
  cursor: pointer;
}

.popup-container {
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s;
  pointer-events: none;
  position: fixed;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  z-index: 1000000000;
  transform: translateY(-81px);
}

.show {
  opacity: 1;
  pointer-events: all;
}

.mobile-menu-category {
  color: #00375f;
  font-family: "helveticaneuebold";

  font-size: 16px;
}

.mobile-menu-subcategory {
  color: #1582c6;
  font-size: 16px;
}

.menu-items-mobile-container {
  padding: 70px 100px;
}

.img-container-top {
  top: 30px;
  right: 0;
}

.img-container-top img {
  width: 80%;
}

.img-container-bottom {
  bottom: 25px;
  left: 30px;
  opacity: 0.5;
}

.marg-top {
  margin-top: 81px;
}

.img-container-bottom img {
  width: 70%;
}

.middle-button {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.buttons-container {
  padding-left: 30px;
  padding-right: 30px;
  background: #fff;
}

.buttons-row button {
  height: 40px;
  background-color: #00375f;
  color: #fff;
  border: none;
  font-size: 16px;
}

.footer-section {
  background-color: #00375f;
}

.excerpt-container p {
  color: #fff;
  margin-bottom: 0;
}

.excerpt-container {
  padding-bottom: 20px;
}

.middle-logo-container {
  opacity: 0.6;
}

.images-container {
  display: flex;
  align-items: flex-end;
  padding-bottom: 20px;
  padding-top: 54px;
}

.trans {
  display: flex;
  align-items: flex-end;
}

.trans img {
  width: 150px;
}

.letter-title p {
  color: #fff;
  font-size: 16px;
}

.letter-wrapper {
  display: flex;
  align-items: flex-start;
}

.letter-wrapper input,
button {
  height: 45px;
  background-color: transparent;
  border: #fff solid 1px;
  border-radius: 3px;
}

/* .letter-wrapper input::-webkit-input-placeholder {
  color: red;
} */

.letter-wrapper button {
  border-radius: 3px;
}

.letter-wrapper input:focus {
  border: 1px solid #fff;
}

.letter-wrapper input:focus-visible {
  border: 1px solid #fff !important;
}

.letter-button {
  right: 0;
  border-radius: 3px;
}

.letter-wrapper input::placeholder {
  color: #fff;
  font-family: helveticaneueitalic;
  font-style: italic;
  font-size: 12px;
}

.letter-wrapper button {
  color: #fff;
  font-size: 14px;
  transition: 0.5s;
}

@media (hover: hover) {
  .letter-wrapper button:hover {
    color: #00375f;
    background-color: #fff;
  }
}

.footer-hr {
  background-color: #fff;
  height: 2px;
  margin: 0 !important;
  opacity: 1;
}

.contact-footer {
  align-items: center;
  margin-left: -5px;
}

.contact-footer p {
  color: #fff;
  font-size: 16px;
  transition: 0.5s;
  padding-left: 5px;
  border-left: transparent solid 1px;
}

.contact-footer p:before {
  content: "";
  position: absolute;
  left: -3px;
  height: 100%;
  border-left: 3px solid #e30613;
  opacity: 0;
  transition: 0.5s;
}

@media (hover: hover) {
  .contact-footer p:hover {
    color: #e30613;
  }

  .contact-footer p:hover:before {
    opacity: 1;
  }
}

input[type="email"] {
  color: #fff;
  font-family: HelveticaNeueItalic;
  padding-left: 10px;
}

.designed-by {
  padding-top: 21px;
  color: #fff;
  font-size: 12px;
}

.designed-by-div a {
  color: #fff;
  font-size: 12px;
  text-decoration: none;
  border-bottom: transparent solid 1px;
  transition: 0.5s;
}

.designed-by-div a:hover {
  color: #fff;
  border-bottom: #fff solid 1px;
}

.terms-popup {
  z-index: 99999999999999;
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: rgba(0, 55, 95, 0.5);
  top: 0;
  left: 0;
  transition: 0.5s all;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
}

.terms-content {
  background-color: white;
  width: 850px;
  max-width: 850px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
}

.scrollable-container {
  overflow-y: auto;
  flex-grow: 1;
}

.privacy-title {
  display: flex;
}

.privacy-title {
  overflow-y: auto;
  flex-grow: 1;
}

.terms-title {
  color: #00375f;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 0;
}

.terms-title-container {
  justify-content: center;
  display: flex;
  align-items: center;
}

.terms-row {
  padding-top: 40px;
  padding-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
}

.border-terms {
  width: 96%;
  margin: auto;
  height: 1px;
  background-color: #e30613;
}

.border-terms.first {
  width: 100%;
}

.border-terms .one {
  bottom: -0.5px;
  width: 50%;
  height: 3px;
  background-color: #e30613;
  position: absolute;
  transition: 0.7s;
}

.active {
  color: #e30613;
  font-family: "helveticaneuebold";
}

.privacy-title {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.privacy-title.conditions {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.privacy-title h1 {
  font-size: 20px;
  color: #00375f;
  font-family: "helveticaneuebold";
  margin-bottom: 0;
}

/* .privacy-content{
  margin-bottom: 56px;
} */

.privacy-content p {
  color: #00375f;
  font-size: 16px;
  padding-top: 30px;
  padding-bottom: 56px;
}

.privacy-content p:last-child {
  padding-bottom: 20px;
}

.privacy-content.test p {
  padding-bottom: 0;
}

.accept-btn-container {
  margin-bottom: 30px;
  margin-top: 56px;
}

.accept-button {
  color: #00375f;
  height: 45px;
  border: #00375f solid 1px;
  width: 130px;
  border-radius: 3px;
  transition: 0.5s;
}

@media (hover: hover) {
  .accept-button:hover {
    background-color: #00375f;
    color: #fff;
  }
}

.next-button {
  cursor: pointer;
  color: #1582c6;
  transition: 0.5s;
  margin-bottom: 0;
}

.next-btn-container {
  margin-top: 30px;
}

.icon-container img {
  transform: translateY(0);
  cursor: pointer;
  transition: 0.5s;
  max-width: 30px;
}

.about-img-container img {
  height: 600px;
  width: 100%;
  object-fit: cover;
}

.about-earrel-container {
  margin-bottom: 90px;
}

.about-earrel-content {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 37px 40px;
}

.about-erreal {
  font-family: "helveticaneuebold";

  font-size: 30px;
  color: #00375f;
  padding-bottom: 30px;
  margin-bottom: 0;
}

li {
  list-style-position: inside;
}

.partners-title h1 {
  font-family: "helveticaneuebold";

  font-size: 20px;
  padding-bottom: 30px;
}

.partners-images {
  width: 190px;
  height: 190px;
  object-fit: contain;
}

.about-earrel-content-mobile {
  padding-top: 30px;
}

.about-erreal-excerpt {
  text-align: justify;
}

.about-erreal-excerpt p {
  margin-bottom: 0;
}

.about-erreal.mobile {
  padding-bottom: 0;
}

.about-erreal-excerpt.mobile {
  padding: 30px 20px;
}

/*================================================*/

.partner-column {
  transition: 0.5s;
}

.partner-column .mobile-partners-content {
  transition: 0.5s all;
  height: auto !important;
}

.partner-column .mobile-partners-content {
  padding: 20px;
}

.partner-column img {
  transition: 0.5s;
}

/* @media (pointer: coarse) and (min-width: 991.9px) {
  .mobile-partners-content {
    transform: scale(1.1);
    z-index: 100;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  
    background-color: #fff;
  }

  .partner-column img {
    height: 90px;
    width: 90px;
  }
  
  .partner-column .partners-content {
    opacity: 1;
  }
  
  .partner-column .partners-excerpt-mobile {
    opacity: 1 !important;
  }

} */

@media screen and (min-width: 1200.1px) {
  .open-faq {
    padding-right: 70px !important;
  }
}

@media (min-width: 991.9px) {
  .partner-column:hover .mobile-partners-content {
    transform: scale(1.5);
    transform-origin: top;
    z-index: 100;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;

    background-color: #fff;
  }

  .partner-column:hover img {
    height: 90px;
    width: 90px;
  }

  .partner-column:hover .partners-content {
    opacity: 1;
  }

  .partner-column:hover .partners-excerpt-mobile {
    opacity: 1;
    font-size: 14px;
  }
}

/*=================================================*/

.partners-title-mobile h1 {
  font-size: 16px;
  color: #e30613;
  font-family: "helveticaneuebold";
}

@media (hover: hover) {
  .icon-container:hover img {
    transform: translateY(-3px);
  }
}

@media (hover: hover) {
  .next-button:hover {
    /* border-bottom: #1582c6 solid 1px; */
    text-decoration: underline;
  }
}

.privacy-content.conditions {
  padding-left: 18px;
  padding-right: 18px;
  margin-bottom: 0;
}

.privacy-content.conditions p {
  padding-bottom: 30px;
}

.privacy-content.conditions.last p {
  padding-bottom: 0;
}

.conditions-row {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.conditions-row hr {
  height: 1px;
  position: relative;
  border: none;
  /* color: #e30613; */
  opacity: 1;
  background: #e30613;
  margin-bottom: 25px;
}

.conditions-row p {
  margin-bottom: 0;
  padding: 0;
}

.conditions-row p:not(:last-child) {
  padding-bottom: 30px;
}

.conditions-row ol {
  margin-bottom: 0;
  padding: 0;
}

.conditions-row ol:not(:last-child) {
  padding-bottom: 30px;
}

.conditions-row h2 {
  font-family: "Helveticaneuebold";
  font-size: 20px;
  margin-bottom: 0;
  padding: 0;
}

.conditions-row h3 {
  font-family: "Helveticaneuebold";
  font-size: 20px;
  margin-bottom: 0;
  padding: 0;
}

.conditions-row h4 {
  font-family: "Helveticaneuebold";
  font-size: 20px;
  margin-bottom: 0;
  padding: 0;
}

.conditions-row h5 {
  font-family: "Helveticaneuebold";
  font-size: 20px;
  margin-bottom: 0;
  padding: 0;
}

.conditions-row h6 {
  font-family: "Helveticaneuebold";
  font-size: 20px;
  margin-bottom: 0;
  padding: 0;
}

.conditions-row h1 {
  font-family: "Helveticaneuebold";
  font-size: 20px;
  margin-bottom: 0;
  padding: 0;
}

.privacy-title-container {
  padding-right: 0px;
  padding-left: 0px;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
}

.privacy-content p {
  margin-bottom: 0;
}

.privacy-subtitle h1 {
  padding-top: 50px;
}

/* .border-terms.conditions {
  margin-bottom: 30px !important;
}

.border-terms.conditions.first {
  margin-bottom: 50px !important;
} */

.conditions-h1 {
  margin-bottom: 0;
}

/* .partners {
  padding-bottom: 170px;
} */

.about-lta-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-description {
  text-align: justify;
}

/* .partners-content {
  text-align: justify;
} */

.research-description {
  text-align: justify;
}

.about-description p:last-child {
  margin-bottom: 0;
}

.about-lta-title {
  font-size: 30px;
  font-family: "helveticaneuebold";

  padding-bottom: 50px;
  margin-bottom: 0;
}

.about-lta-content {
  padding: 50px 20px;
}

.about-lta {
  margin-bottom: 90px;
}

section.objectives {
  padding-bottom: 75px;
}

.objectives-title h1 {
  font-size: 20px;
  font-family: "helveticaneuebold";

  padding-bottom: 30px;
  margin-bottom: 0;
}

.icon-obj-container {
  border: #00375f solid 1px;
  border-radius: 50%;
  padding: 25px;
  margin-bottom: 20px;
}

.icon-obj-container img {
  height: 100px;
  width: 100px;
}

.excerpt-obj-container {
  text-align: center;
  padding: 15px;
}

.excerpt-obj-container p {
  margin-bottom: 0;
}

.excerpt-obj-container.swiper p {
  padding-bottom: 30px;
}

.swiper-container {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.swiper-pagination-bullet {
  background-color: #00375f !important;
  opacity: 1 !important;
}

.obj-swiper {
  padding-bottom: 40px;
}

.swiper-pagination {
  bottom: -5px !important;
}

.swiper-pagination-bullet-active {
  background-color: #e30613 !important;
}

.research-title {
  background-color: #00375f;
  text-align: center;
  margin-bottom: 20px;
}

.research-title h1 {
  margin-bottom: 0;
  color: #fff;
  font-family: "helveticaneuebold";

  font-size: 20px;
  padding: 20px;
}

.research-description {
  border: #00375f solid 1px;
  padding: 20px;
}

.research-description p:last-child {
  margin-bottom: 0;
}

.research-description ol {
  margin-bottom: 0;
  padding-left: 0;
}

.research-description li:last-child {
  margin-bottom: 0;
}

.research:nth-child(1) {
  margin-top: 80px;
}

.research:nth-child(3) {
  margin-top: 80px;
}

.research-description * {
  margin-bottom: 16px;
}

.research-container {
  padding-bottom: 90px;
}

.map-title h1 {
  font-size: 30px;
  font-family: "helveticaneuebold";

  margin-bottom: 50px;
}

.map-description p {
  margin-bottom: 0;
}

.map-description {
  text-align: justify;
}

.video-map {
  width: 100%;
}

.beirut-map {
  padding-bottom: 90px;
}

.content {
  height: 100vh;
}

.partners-col img {
  transform: translate(0);
  transition: 0.5s;
}

@media (hover: hover) {
  .partners-col:hover img {
    width: 100px;
    height: 100px;
  }
}

.partners-content {
  pointer-events: none;
}

.partners-col {
  transform: translate(0);
  transition: 0.5s;
}

.mobile-row-partners .partner-column {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.content-container-404 {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 35;
}

.light {
  top: 170px;
  opacity: 0.5;
  width: 35%;
}

@media (hover: hover) {
  .partners-col:hover {
    transform: scale(1.4, 1.2);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    padding: 20px;
    background-color: #fff;
  }

  .partners-col:hover .partners-content {
    opacity: 1;
  }

  .partners-col:hover .partners-content .partners-excerpt-mobile {
    opacity: 1;
  }
}

/* .partners-col.partners-col-1:hover{
  transform: translate(-60px,-20px) !important;

  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  padding: 20px;
}

.partners-col.partners-col-2:hover{
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  padding: 20px;
  transform: scale(1.1);
}

.partners-col.partners-col-3:hover{
  transform: translate(60px,-20px) !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  padding: 20px;
  transform: scale(2);
} */

.partners-content {
  transform: translate(0);

  opacity: 0;
  transition: 0.5s;
}

.partners-excerpt-mobile p {
  margin-bottom: 0;
  font-size: 14px;
  color: #00375f;
}

.terms-title.privacy {
  color: #e30613;
}

.terms-title.terms.active-terms {
  font-family: helveticaneueBold;
}

.partners-content .partners-excerpt-mobile {
  opacity: 0;
  transition: 0.5s;
  transition-delay: 0.2s;
}

.mobile-row-partners {
  align-items: flex-start;
}

.partners-title-mobile {
  text-align: center;
}

.main-404 {
  height: 100vh;
  width: 100%;
  background-image: url("/public/assets/images/404-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 0;
  overflow-y: auto;
}

.content-404 {
  /* height: 100%; */
}

.main-404 p {
  margin-bottom: 0;
}

.title-404 h1 {
  font-size: 250px;
  line-height: 1;
  font-family: "montserratSemi";
  margin-bottom: 0;
}

.not-found {
  margin-bottom: 50px;
}

.page-not-found p {
  font-size: 45px;
  color: #e30613;
}

.request-not-found p {
  font-size: 30px;
}

.menu-items-mobile a {
  text-decoration: none;
}

.logo-404 {
  position: absolute;
  top: 20px;
  left: 60px;
}

.about-image-col {
  padding-right: 20px !important;
}

.middle-lamp {
  display: flex;
}

.first-lamp {
  display: flex !important;
}

.trans:first-child {
  padding-left: 3px;
}

.conditions-row h2:first-child {
  padding-top: 50px;
}

.faq-banner-content {
  padding: 30px;
}

.faq-title * {
  font-size: 30px;
  font-family: "helveticaneuebold";
  color: #fff;
  margin-bottom: 0;
  padding-bottom: 20px;
  line-height: normal;
}

.faq-banner-content {
  background-color: #00375f;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.faq-excerpt p {
  color: #fff;
  margin-bottom: 0;
}

.faq-image img {
  height: 450px;
  width: 100%;
  object-fit: cover;
}

.faq-banner {
  margin-bottom: 90px;
}

.faqs-section {
  padding-bottom: 90px;
}

.open-faq {
  color: #e30613;
  font-family: "helveticaneueblackcond" !important;
  font-size: 20px;
  cursor: pointer;
}

.question * {
  font-family: "helveticaneuebold";
  font-size: 20px;
  margin: 0;
  padding: 0;
  padding-top: 20px;
  padding-bottom: 20px;
}

.faq-question-answer {
  border: #00375f solid 1px;
  cursor: pointer;
}

.faq-question-answer:not(:last-child) {
  margin-bottom: 15px;
}

.no-height {
  height: 0;
  /* opacity: 0; */
  overflow: hidden;
  transition: 0.5s;
}

.answer p {
  margin-bottom: 30px;
  padding-top: 10px;
}

.sectoral-section {
  padding-top: 90px;
  margin-bottom: 35px;
}

.sectors-section a {
  color: inherit;
}

.sectors-section a:hover {
  color: inherit;
}

.sector-section-title h1 {
  font-family: "helveticaneuebold";
  font-size: 30px;
  margin-bottom: 50px;
  color: #fff;
}

.sector-section-excerpt * {
  padding: 0;
  margin: 0;
  color: #fff;
}

.sectoral-categories {
  background-color: #00375f;
  padding: 80px 63px;
  top: 0;
  left: 0;
}

.single-sector {
  padding: 42px 30px 20px;
  border: 2px solid #00375f;
  border-top: none;
  /* margin-bottom: 55px; */
  /* transition: 0.5s all; */
  display: flex;
  flex-direction: column;
  height: 100%;
}

.single-sector .border-top-sector {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-image: linear-gradient(to right, #00375f calc(100% - 60px), rgba(255, 255, 255, 0) 0%);
  background-position: 75px 0px;
  background-size: 100% 2px;
  background-repeat: repeat-x;
  transition: 0.5s;
}



.single-sector.home {

  border: 2px solid #fff;
  border-top: none;
}

.single-sector.home .border-top-sector {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-image: linear-gradient(to right, #fff calc(100% - 60px), rgba(255, 255, 255, 0) 0%);
  background-position: 75px 0px;
  background-size: 100% 2px;
  background-repeat: repeat-x;
  transition: 0.5s;
}

.sector-excerpt.home p {
  color: #fff;
}

.sector-title.home h1 {
  color: #fff;
}

.home-reports-title h1 {
  font-size: 30px;
  font-family: 'helveticaneuebold';
  margin-top: 90px;
  margin-bottom: 50px;
}

.home-reports-excerpt p {
  margin-bottom: 172px;
  text-align: center;
}

.home-reports-wrapper {
  background-color: rgba(0, 55, 95, 0.2);
}

.home-reports-row {
  transform: translateY(-122px);
}

@media (hover: hover) {
  .single-sector:hover {
    border-color: #e30613;
  }

  .single-sector:hover .border-top-sector {
    background-image: linear-gradient(to right, red calc(100% - 60px), rgba(255, 255, 255, 0) 0%);
    transition: 0.5s all ease-in-out;
  }
}

.sector-title h1 {
  font-family: "helveticaneuebold";
  font-size: 20px;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.sector-image img {
  object-fit: cover;
}

.sector-icon {
  top: -22.5px;
  background-color: white;
  left: 18px;
  padding: 0 5px;
}

.sector-icon.home {

  background-color: transparent;
}

.border-top-custom {
  background-image: linear-gradient(to right, white 90%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 400px 1px;
  background-repeat: repeat-x;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.sector-icon.home img {

  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(109%) contrast(102%);

}

.sector-icon img {
  max-width: 45px;
  max-height: 45px;
}

.sectors-col {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.sector-div-logo {
  top: 50px;
  right: 33px;
  width: 20%;
  opacity: 0.5;
}

.blue-sector-div {
  position: absolute;

  margin-bottom: 55px;
}

.logo-sector-container {
  top: 40px;
  right: 30px;
}

.logo-sector-container img {
  width: 80px;
  opacity: 0.3;
}

.sector-excerpt {
  flex-grow: 1;
}

.sector-excerpt p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.single-sector-image img {
  object-fit: cover;
}

.single-sector-section {
  padding-top: 176px;
  padding-bottom: 176px;
}

.single-sector-logo img {
  width: 75px;
  height: 75px;
}

.single-sector-title h1 {
  font-family: "helveticaneuebold";
  font-size: 30px;
  margin-bottom: 0;
  padding-left: 20px;
}

.single-sector-image-title {
  margin-bottom: 25px;
}

.single-sector-image-col {
  margin-right: 50px;
  padding-right: 0 !important;
}

.single-sector-description p {
  margin-bottom: 0;
  text-align: justify;
}

.single-sector-description {
  margin-right: 50px;
}

.single-sector-description a {
  text-decoration: underline !important;
  color: var(--bs-link-color) !important;
}

.single-sector-description.tracker {
  text-align: justify;
}

.single-sector-left-section {
  top: 90px;
  bottom: 90px;
  left: 0;
  /* height: 75%; */
  background-color: #00375f;
  z-index: -1;
}

.single-sector-right-section {
  top: 90px;
  bottom: 90px;
  right: 0;
  /* height: 75%; */
  background-color: #00375f;
  z-index: -1;
}

.ratio-single-sector {
  --bs-aspect-ratio: 100%;
}

.sub-sector-title h1 {
  font-family: "Helveticaneuebold";
  font-size: 20px;
  padding-bottom: 15px;
  margin-bottom: 0;
  text-align: center;
  transition: 0.5s all;
}

.sub-sector-icon-title:hover .sub-sector-title h1,
.sub-sector-title.active-sub h1 {
  color: #fff;
}

.sub-sector-icon-title:hover,
.sub-sector-icon-title.active-sub {
  background-color: #00375f;
}

/* .subsectors-row{
    margin-bottom: 30px;
  } */

.sub {
  margin-bottom: 30px;
}

.triangle-down {
  width: 0;
  height: 0;
  bottom: -18px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 18px solid #00375f;
}

.sub-sector-icon-title {
  background-color: #fff;
  width: 100%;
  height: 100%;
  transition: 0.5s;
}

.sub-sector-icon {
  margin: 25px 40px;
  padding: 15px 20px;
  border: 1px solid #00375f;
  border-radius: 50%;
}

.sub-sector-icon {
  background-color: #fff;
  transition: 0.5s all;
}

.sub-sector-icon img {
  width: 63px;
  height: 70px;
}

.sub {
  border: 1px solid #00375f;
  margin-right: 10px;
  margin-left: 10px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  cursor: pointer;
  z-index: 1222;
}

.description-subsector {
  padding: 30px 30px 20px !important;
}

.description-subsector-mobile {
  background-color: #00375f;

  padding: 0;
}

.subsector-description {
  top: 0;
  left: 0;
  transition: 0.5s;
  background-color: #00375f;
}

.description-subsector-mobile * {
  color: #fff;
  padding: 0;
  margin: 0;
  transition: 0.5s;
}

.description-subsector-mobile>* {
  color: #fff;
  padding: 0;
  margin: 30px 30px 20px;
  transition: 0.5s;
}

.description-subsector * {
  color: #fff;
  padding: 0;
  margin: 0;
}

.description-subsector-mobile a {
  text-decoration: underline !important;
  color: var(--bs-link-color) !important;
}

.description-subsector a {
  text-decoration: underline !important;
  color: var(--bs-link-color) !important;
}

.sub-sectors {
  margin-bottom: 90px;
}

.clicked-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.single-sector-top-section {
  background: #00375f;
  width: 100%;
  top: 0;
  height: 250px;
  z-index: -1;
  height: calc(348px - 90px);
}

.terms-and-conditions-title h1 {
  font-family: "helveticaneuebold";
  font-size: 20px;
  margin-bottom: 0;
  transition: 0.5s all;
  text-align: center;
}

.privacy-policy-title h1 {
  font-family: "helveticaneuebold";
  font-size: 20px;
  margin-bottom: 0;
  transition: 0.5s all;
  text-align: center;
}

.terms-col {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border: 1px solid #00375f;
  background-color: #fff;
  transition: 0.5s;
  cursor: pointer;
}

.privacy-col {
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #00375f;
  background-color: #fff;
  transition: 0.5s;
  cursor: pointer;
}

.terms-col:hover {
  background-color: #00375f;
}

.terms-col:hover .terms-and-conditions-title h1 {
  color: #fff;
}

.privacy-col:hover {
  background-color: #00375f;
}

.privacy-col:hover .privacy-policy-title h1 {
  color: #fff;
}

.terms-col.active-title {
  background-color: #00375f;
}

.terms-col.active-title .terms-and-conditions-title h1 {
  color: #fff;
}

.privacy-col.active-title {
  background-color: #00375f;
}

.privacy-col.active-title .privacy-policy-title h1 {
  color: #fff;
}

.terms-privacy-container {
  padding-top: 90px;
}

.titles-row {
  padding-bottom: 90px;
}

.terms-description-section * {
  margin: 0;
  padding: 0;
}

.terms-description-section h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "helveticaneuebold";
  font-size: 20px;
  margin-bottom: 50px !important;
}

.terms-description-section p,
ol {
  margin-bottom: 70px !important;
}

.reports-title {
  margin: auto;
  margin-top: 170px;
  margin-bottom: 50px;
}

.reports-title h1 {
  text-align: center;
  margin-bottom: 0;
  font-family: "helveticaneuebold";
  font-size: 30px;
}

.report-filter-row {
  margin-bottom: 40px;
}


.sectoral-filter {
  border: #00375f 1px solid;
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;

}

/* @media (hover: hover) {
  .sectors-col-filter:hover img {
    transform: rotateX(180deg);
  }

  .subsectors-col-filter:hover img {
    transform: rotateX(180deg);
  }

  .donors-col-filter:hover img {
    transform: rotateX(180deg);
  }

  .years-col-filter:hover img {
    transform: rotateX(180deg);
  }
} */




.rotate-arrow {
  transform: rotateX(180deg);
}



.sectoral-filter-title p {
  font-family: "helveticaneuemedium";
  margin-bottom: 0;
}

.sectoral-filter img {
  transition: 0.5s all;
}

.clear-all-label {
  margin-bottom: 0;
  font-size: 14px;
  color: #1582c6;
  cursor: pointer;
  padding-top: 10px;
  transition: 0.5s;
}





.default-pdf {
  object-fit: scale-down;
  width: 100%;
}

.cover-pdf {
  /* transform: scale(1.1) translateY(20px); */
  width: 100%;
}

.pdf-overlay {
  z-index: 1;
  opacity: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to top, #00375F 0%, #fff0 35%);
  transition: 0.5s;
}

.survey-image .pdf-overlay::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, #00375f 0%, #fff0 100%);
  z-index: -1;
  transition: opacity 0.5s linear;
  opacity: 0;
}

.pdf-image .pdf-overlay::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, #00375f 0%, #fff0 80%);
  z-index: -1;
  transition: opacity 0.5s linear;
  opacity: 0;
}


@media (hover: hover) {
  .survey-image .pdf-overlay:hover::before {
    opacity: 1;
  }

  .pdf-image .pdf-overlay:hover::before {
    opacity: 1;
  }

  .pdf-overlay:hover .pdf-title p {
    -webkit-line-clamp: 5;
  }

  .pdf-image:hover img {
    transform: scale(1.05);
  }

  .pdf-hover:hover .pdf-download {
    filter: invert(57%) sepia(80%) saturate(6404%) hue-rotate(331deg) brightness(61%) contrast(128%);
  }

  .survey-image:hover img {
    transform: scale(1.1);
  }

  .survey-image:hover .pdf-title.survey p {
    text-decoration: underline;
  }
}


.pdf-image {
  overflow: hidden;
}

.pdf-image img {
  transition: 0.5s;
}




.report-content {
  margin-bottom: 20px;
}

.pdf-col {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.pdf-title {
  height: 100%;
}

.pdf-title p {
  color: #fff;
  bottom: 0;
  font-family: 'helveticaneuebold';
  left: 15px;
  margin-bottom: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.pdf-download {
  bottom: 0;
  right: 15px;
  bottom: 15px;
}





.pdfs-container {
  margin-bottom: 30px;
}

.prev-arrow,
.next-arrow {
  height: 40px;
  width: 40px;
  background-color: #00375F;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.prev-arrow-modal,
.next-arrow-modal {
  height: 50px;
  width: 50px;
  background-color: #00375F;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
}






.cancel-modal {
  top: -50px;
  right: 20%;
  height: 50px;
  width: 50px;
  background-color: #00375F;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
}

.cancel-modal img {
  width: 100% !important;
}

.prev-arrow-modal img {
  transform: rotate(180deg);
}

.prev-arrow img {
  transform: rotate(180deg);
}

.page-div {
  height: 40px;
  width: 40px;
  border: 1px solid #00375F;
  cursor: pointer;
  transition: 0.5s;
}

.page-div:hover {
  background-color: #00375F;
}

.page-div:hover p {
  color: #fff;
}

.page-div p {
  margin-bottom: 0;
}

.page-div.active-page {
  background-color: #00375F;

}

.page-div.active-page p {
  color: #fff;
}

.low-opacity {
  opacity: 0.5;
}

/* ===================================================================*/

.sectors-dropdown {
  border: 1px solid #00375F;
  border-radius: 3px;
  padding: 15px;
  width: calc(100% - 24px);
  background: white;
  z-index: 10;
  opacity: 0;
  top: 56px;
  pointer-events: none;
  transition: 0.5s;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;

}

.subsectors-dropdown {
  border: 1px solid #00375F;
  border-radius: 3px;
  padding: 15px;
  width: calc(100% - 24px);
  background: white;
  z-index: 10;
  opacity: 0;
  top: 56px;
  pointer-events: none;
  transition: 0.5s;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;

}

.donors-dropdown {
  border: 1px solid #00375F;
  border-radius: 3px;
  padding: 15px;
  width: calc(100% - 24px);
  background: white;
  z-index: 10;
  opacity: 0;
  top: 56px;
  pointer-events: none;
  transition: 0.5s;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;

}

.years-dropdown {
  border: 1px solid #00375F;
  border-radius: 3px;
  padding: 15px;
  width: calc(100% - 24px);
  background: white;
  z-index: 10;
  opacity: 0;
  top: 56px;
  pointer-events: none;
  transition: 0.5s;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;

}

.sectors-col-filter {
  padding-bottom: 10px;
}

.subsectors-col-filter {
  padding-bottom: 10px;
}

.donors-col-filter {
  padding-bottom: 10px;
}

.years-col-filter {
  padding-bottom: 10px;
}

.clicked-on-dropdown {
  opacity: 1;
  pointer-events: all;
}

.sectors-dropdown.activee {
  opacity: 1;
  pointer-events: all;
}

.subsectors-dropdown.activee {
  opacity: 1;
  pointer-events: all;
}

.donors-dropdown.activee {
  opacity: 1;
  pointer-events: all;
}

.years-dropdown.activee {
  opacity: 1;
  pointer-events: all;
}



/* @media (hover: hover) {
  .sectors-col-filter:hover .sectors-dropdown {
    opacity: 1;
    pointer-events: all;
  }

  .subsectors-col-filter:hover .subsectors-dropdown {
    opacity: 1;
    pointer-events: all;
  }

  .donors-col-filter:hover .donors-dropdown {
    opacity: 1;
    pointer-events: all;
  }

  .years-col-filter:hover .years-dropdown {
    opacity: 1;
    pointer-events: all;
  }

 
} */

@media (hover: hover) {
  .clear-all-label:hover {
    color: #e30613;
  }

  .prev-arrow-modal:hover,
  .next-arrow-modal:hover {
    background-color: #fff;
  }

  .prev-arrow-modal:hover img,
  .next-arrow-modal:hover img {
    filter: invert(16%) sepia(19%) saturate(17085%) hue-rotate(182deg) brightness(50%) contrast(103%);

  }

  .cancel-modal:hover {
    background-color: #fff;
  }

  .cancel-modal:hover img {
    filter: invert(16%) sepia(19%) saturate(17085%) hue-rotate(182deg) brightness(50%) contrast(103%);

  }
}








.sectors-dropdown:hover {
  opacity: 1;
  pointer-events: all;
}

.sectors-dropdown p {
  font-family: 'helveticaneuemedium';
  cursor: pointer;
  transition: 0.5s;
}

.sectors-dropdown a:not(:last-child) {
  margin-bottom: 15px;
}

.subsectors-dropdown a:not(:last-child) {
  margin-bottom: 15px;
}

.donors-dropdown a:not(:last-child) {
  margin-bottom: 15px;
}

.years-dropdown a:not(:last-child) {
  margin-bottom: 15px;
}


.sectors-dropdown p:hover {
  color: #e30613;
}

.sectors-dropdown p:last-child {
  margin-bottom: 0;
}

.subsectors-dropdown:hover {
  opacity: 1;
  pointer-events: all;
}

.subsectors-dropdown p {
  font-family: 'helveticaneuemedium';
  cursor: pointer;
  transition: 0.5s;
}

.subsectors-dropdown p:hover {
  color: #e30613;
}

.subsectors-dropdown p:last-child {
  margin-bottom: 0;
}

.donors-dropdown:hover {
  opacity: 1;
  pointer-events: all;
}

.donors-dropdown p {
  font-family: 'helveticaneuemedium';
  cursor: pointer;
  transition: 0.5s;
}

.donors-dropdown p:hover {
  color: #e30613;
}

.donors-dropdown p:last-child {
  margin-bottom: 0;
}

.years-dropdown:hover {
  opacity: 1;
  pointer-events: all;
}

.years-dropdown p {
  font-family: 'helveticaneuemedium';
  cursor: pointer;
  transition: 0.5s;
}

.years-dropdown p:hover {
  color: #e30613;
}

.years-dropdown p:last-child {
  margin-bottom: 0;
}

/*===============================================================*/

.pagination-container {
  margin-bottom: 90px;
}

.surveys-title {
  margin-top: 90px;
  margin-bottom: 30px;
}

.surveys-title h1 {
  font-family: 'helveticaneueBold';
  font-size: 30px;
}

.survey-description * {
  margin: 0;
  padding: 0;
}

.survey-description ul {
  list-style: none;
}

.survey-description ul li:before {
  content: "\2022";
  color: #e30613;
  font-weight: bold;
  display: inline-block;
  width: 1em;
}

.surveys-description-row {
  margin-bottom: 40px;
}

.clear-all-label.surveys {
  padding: 0;
  padding-bottom: 10px;
}

.survey-image {
  transition: all 250ms linear;
  overflow: hidden;
}

.survey-image img {
  object-fit: cover;
  transition: 0.5s;
}



.row.surveys {
  margin-bottom: 50px;
}

.pdf-title.survey p {
  z-index: 10;
  border-bottom: transparent solid 1px;
  transition: 0.5s;
}

.sectoral-filter.chosen {
  background-color: #00375F;
  color: #fff;
  margin-top: 10px;
}

.sectoral-filter.chosen.mobile {
  margin-top: 0 !important;
  margin-bottom: 10px !important;
}

.remove-filter {
  width: 10px;
  height: 10px;
  cursor: pointer;
}



.no-results h1 {
  font-size: 30px;
  font-family: 'helveticaneuebold';
  margin-bottom: 0;
}

.contact-banner {
  padding-bottom: 90px;
}

.banner-content {
  height: 600px;
  max-height: 600px;
}

.banner-image {
  position: absolute;
  width: 70%;
  max-width: 70%;
  height: 600px;
  max-height: 600px;
  z-index: -1;
}

.banner-image-img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;

}

.get-in-touch {
  background-color: #00375F;
  /* right: -40%;
  top: 50%;
  transform: translateY(-50%); */
  padding: 50px;
}

.get-in-touch-title h1 {
  color: #fff;
  font-family: 'helveticaneuebold';
  font-size: 30px;
}

.location-text p {
  color: #fff;
  line-height: 1.3;
  margin-bottom: 0;
}

.location-icon,
.email-icon,
.telephone-icon {
  padding-right: 20px;
  display: flex;
  align-items: flex-start;

}

.location-contact,
.email-contact {
  margin-bottom: 30px;
  align-items: center;
}



.location-icon img,
.email-icon img,
.telephone-icon img {
  max-height: 24px;
  max-width: 24px;

}



.map-container {
  position: absolute;
  height: 450px;
  width: 40%;
  border: 0;
}

.contact-input {
  height: 40px;
  margin-bottom: 20px;
  border: 1px solid #00375F;
  padding: 14px;
}

.contact-input.message {
  height: 80px;
  margin-bottom: 10pxw;
}

section.contact-us {
  height: 550px;
  min-height: 550px;
  margin-bottom: 90px;
}

.contact-form input::placeholder {
  color: #00375F;
  font-family: helveticaneueitalic;
  font-style: italic;
  font-size: 12px;
}

input[type="email"].contact-input {
  color: #00375F;
  font-family: HelveticaNeueItalic;
  padding-left: 10px;
}

input[type="text"].contact-input {
  color: #00375F;
  font-family: HelveticaNeueItalic;
  padding-left: 10px;
}

.contact-title {
  background-color: #00375F;
  z-index: 10;
  position: relative;
}

.contact-us-content {
  background-color: #00375F;
  z-index: 10;
  position: relative;
  padding: 30px;
}

.contact-title h1 {
  font-family: "helveticaneuebold";
  font-size: 30px;
  color: #fff;
}

.contact-excerpt p {
  color: #fff;
  margin-bottom: 0;
}

.success-message {
  margin-bottom: 26px;
}

.success-message p {
  font-family: "helveticaneueitalic";
  font-size: 12px;
  color: #33AA24;
}

.send-btn {
  border: 1px solid #00375F;
  height: 45px;
  width: 130px;
  font-family: "helveticaneuelight";
  border-radius: 3px;
  transition: 0.5s;
}

.padding-l {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.error-messages p {
  color: #e30613;
  font-family: "helveticaneueitalic";
  font-size: 12px;
}

.red-border {
  border: 1px solid #e30613;
}

.contact-form input:focus-visible {
  border: 2px solid #00375F;
}

input {
  outline: none;
}


@media (hover: hover) {
  .send-btn:hover {
    background-color: #00375F;
    color: #fff;
  }
}




.contact-form {
  margin-bottom: 53px;
}

.url-loc p {
  transition: 0.5s;
}



section.featured-surveys {
  margin-bottom: 90px;
}

.library-title h1 {
  font-family: "helveticaneuebold";
  font-size: 30px;
  margin-top: 171px;
  text-align: center;
  margin-bottom: 50px;
}

.featured-surveys-wrapper {
  background-color: #00375F;
}

.featured-surveys-title h1 {
  color: #fff;
  font-family: 'helveticaneuebold';
  font-size: 20px;
  margin-bottom: 30px;
}

.featured-surveys-excerpt p {
  color: #fff;
  margin-bottom: 45px;
  text-align: justify;
}

.featured-surveys-content {
  padding: 105px 0;
}

.view-btn {
  width: 130px;
  height: 45px;
  color: #fff;
  font-size: 14px;
  border-radius: 3px;
  transition: 0.5s;
}


.swiper-col {
  margin-left: 60px;
}


.swiper-col .swiper-button-next {
  right: -50px !important;
  height: 50px !important;
  width: 50px !important;
}

.swiper-col .swiper-button-next::after {
  content: "" !important;
}

.swiper-col .swiper-button-prev {
  left: -50px !important;
  height: 50px !important;
  width: 50px !important;
}

.swiper-col .swiper-button-prev::after {
  content: "" !important;
}

.next-survey {
  height: 50px;
  width: 50px;
  transition: 0.5s;
}

.prev-survey {
  height: 50px;
  width: 50px;
  transition: 0.5s;
}

.prev-survey img {
  transform: rotate(180deg);
}

.pdf-overlay.library-survey {
  background: linear-gradient(to top, #00375fcc 0%, #fff0 35%);
  overflow: hidden;
}

.pdf-overlay.library-survey::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, #00375fcc 0%, #fff0 100%);
  z-index: -1;
  transition: opacity 0.5s linear;
  opacity: 0;
}

.pdf-overlay.library-survey:hover::before {
  opacity: 1;
}


.featured-article-title h1 {
  font-size: 20px;
  font-family: 'helveticaneuebold';
  margin-bottom: 30px;
}

.featured-article-excerpt p {
  text-align: justify;
  margin-bottom: 64px;

}

.view-btn.reverted {
  border-color: #00375f;
  color: #00375F;
}

.article-image {
  margin-bottom: 20px;
}

.article-image img {
  object-fit: cover;
}

.article-title h1 {
  font-size: 20px;
  font-family: 'helveticaneuebold';
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;

}

.article-title a {
  color: inherit;
}



.swiper-slide {
  height: auto !important;
}

.article-date p {
  font-size: 14px;
  font-family: 'helveticaneueitalic';
  color: #e30613;
  margin-bottom: 0;
}

.title-date-container {
  margin-bottom: 15px;
}

.article-excerpt {
  margin-bottom: 20px;
}

.article-excerpt p {
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.article-content {
  padding: 30px 20px;
}

.read-more p {
  margin-bottom: 0;
  font-family: 'helveticaneuemedium';
}

.articlesSwiper {
  cursor: grab;

}

.articlesSwiper .swiper-slide {
  padding-right: 15px;
  padding-left: 15px;
}

/* .articlesSwiper .swiper-slide-active{
  padding-right: 0;

}

.articlesSwiper .swiper-slide-next{
  padding-left: 0;

} */

.articlesSwiper:active {
  cursor: grabbing;
}

.articlesSwiper .swiper-button-prev::after {
  content: 'prev' !important;
}

.articlesSwiper .swiper-button-next::after {
  content: 'next' !important;
}

.swiper-button-next1 {
  position: absolute;
  right: -45px !important;
  top: 50%;
  margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

.swiper-button-prev1 {
  position: absolute;
  left: -45px !important;
  top: 50%;
  margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
}

.gallery-section {
  height: 550px;
  max-height: 550px;
  margin-bottom: 90px;
}

.gallery-image-container {
  width: 100%;
}

.gallery-image-container img {
  height: 550px;
  width: 100%;
  object-fit: cover;
}

.gallery-overlay {
  height: 100%;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 55, 95, 0.6);
}

.gallery-title {
  z-index: 1;
  position: relative;
}

.gallery-title h1 {
  font-family: 'helveticaneuebold';
  font-size: 30px;
  color: #fff;
}

.gallery-subtitle {
  z-index: 1;
  position: relative;
  text-align: center;
}

.gallery-subtitle p {
  color: #fff;
  font-size: 30px;
  font-size: 'helveticaneuerelight';
  margin-bottom: 0;
}

.view-btn.gallery {
  z-index: 1;
  position: relative;
}

.btn-container.gallery {
  margin: auto;
  margin-top: 90px;
  margin-bottom: 50px;
}

.zoom-overlay.video {
  opacity: 1;
  background-color: transparent;
  transition: 0.5s;
}

.zoom-overlay.video:hover {
  background-color: rgba(0, 55, 95, 0.5);
}

/* .featured-col img{
  padding-bottom: 20px;
} */

.padding-bott {
  padding-bottom: 20px;
}



.article-swiper .swiper-button-next {
  right: -10px;
  height: 50px !important;
  width: 50px !important;
  transform: translateY(-50%);

}

.article-swiper .swiper-button-next::after {
  content: "" !important;
}

.article-swiper .swiper-button-prev {
  left: -10px;
  height: 50px !important;
  width: 50px !important;
  transform: translateY(-50%);

}

.article-swiper .swiper-button-prev::after {
  content: "" !important;
}

.next-article {
  height: 50px;
  width: 50px;
  transition: 0.5s;
  border-color: #00375F;
  background-color: #00375F;

}

.prev-article {
  height: 50px;
  width: 50px;
  transition: 0.5s;
  border-color: #00375F;
  background-color: #00375F;

}

.prev-article img {
  transform: rotate(180deg);
}

.mySwiper {
  cursor: grab;
}

.mySwiper:active {
  cursor: grabbing;
}

.gallery-page-title h1 {
  font-family: 'helveticaneuebold';
  font-size: 30px;
  text-align: center;
  margin-top: 171px;
  margin-bottom: 60px;
}

.format-container p {
  margin-bottom: 0;
}

input[type="radio"] {
  appearance: none;
  margin-right: 15px;
  width: 20px;
  height: 20px;
  border: 1px solid black;
  border-radius: 50%;
  background-clip: content-box;
  padding: 3px;
}

input[type="radio"]:checked {
  background-color: #e30613;
}

.gallery-image-col {
  padding-right: 10px !important;
  padding-left: 10px !important;
  padding-bottom: 20px;
}

.zoom-overlay {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 55, 95, 0.6);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.5s;
}

.zoom-overlay:hover {
  opacity: 1;
}

.images-popup {
  z-index: 99999999;
  background-color: rgba(0, 55, 95, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-chosen img {
  width: 50%;
  height: 50%;
  object-fit: cover;
}

.image-chosen {
  width: 80%;
  height: 80%;
  align-items: center;
}

.thumb {
  object-fit: cover;
}

.read-more p {
  color: #1582C6;
}

.youtube-iframe-wrapper iframe {
  width: 100%;
  height: 100%;
}

.gallery-image-contaner img {
  object-fit: cover;
}

.clear-all-label.gallery {
  padding-top: 0 !important;
}

.map-contact {
  width: 550px;
  height: 550px;
}

.article-swiper {
  margin-left: 70px;
}

.articles-title h1 {
  font-family: 'helveticaneuebold';
  font-size: 30px;
  text-align: center;
  padding-top: 90px;
  padding-bottom: 50px;
  margin-bottom: 0;
}

.search-input {
  height: 40px;
  border: 1px solid #00375F;
  padding: 10px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.search-btn {
  color: #00375F;
  border: 1px solid #00375F;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  font-size: 14px;
  height: 40px;
  transition: 0.5s;
  right: 0;
}

.search-btn.touch {
  color: #fff;
  background-color: #00375F;
}

.search-input::placeholder {
  font-family: 'helveticaneueitalic';
  font-size: 12px;
}

input[type="text"].search-input {
  color: #00375F;
  font-family: HelveticaNeueItalic;
  padding-left: 10px;
}

.sectoral-filter.article-year {
  height: 40px;
}

.search-row {
  padding-bottom: 50px;
}

.filter-col.gal {
  padding-bottom: 0;
}

.sectoral-filter.chosen.gal {
  margin-top: 10px !important;
}

.single-article-col {
  margin-bottom: 20px;
}

.articles-row {
  opacity: 0;
  transition: 0.5s;
  margin-bottom: 30px;
}

.articles-row.show {
  opacity: 1;
}

.article-banner-content {
  height: 450px;
}

.article-banner-image {
  width: 100%;
}

.article-banner-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.single-article-excerpt h1 {
  color: #fff;
  position: relative;
  font-family: 'helveticaneuebold';
  font-size: 30px;
  z-index: 2;
}

.single-artile-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 55, 95, 0.5);
  z-index: 1;
}

.single-article-banner {
  margin-bottom: 50px;
}

.article-date.single {
  margin-bottom: 30px;
}

.single-article-description * {
  font-family: 'helveticaneuelight';
  font-size: 16px;
  color: #00375F;
  margin-bottom: 0;
}

.single-article-description h1 {
  font-family: 'helveticaneuebold';
  font-size: 30px;
  color: #00375F;
  margin-bottom: 0;

}

.single-article-description h2 {
  font-family: 'helveticaneuebold';
  font-size: 25px;
  color: #00375F;
  margin-bottom: 0;

}

.single-article-description h3 {
  font-family: 'helveticaneuebold';
  font-size: 20px;
  color: #00375F;
  margin-bottom: 0;

}

.single-article-description h4 {
  font-family: 'helveticaneuebold';
  font-size: 20px;
  color: #00375F;
  margin-bottom: 0;

}

.single-article-description h5 {
  font-family: 'helveticaneuebold';
  font-size: 20px;
  color: #00375F;
  margin-bottom: 0;

}

.single-article-description h6 {
  font-family: 'helveticaneuebold';
  font-size: 20px;
  color: #00375F;
  margin-bottom: 0;

}

.single-article-description img {
  max-width: 100%;
  object-fit: cover;
  height: auto !important;
}


.related-articles-title h1 {
  font-family: 'helveticaneuebold';
  font-size: 30px;
  margin-top: 90px;
  margin-bottom: 50px;
}

.add-padd {
  padding-bottom: 90px;
}

.related-articles {
  margin-bottom: 90px;
}

.single-sector-title.tracker h1 {
  padding-left: 0;
}

.single-sector-image-title.tracker {
  margin-bottom: 15px;
}

.single-sector-title.tracker h1 {
  margin-bottom: 50px;
}

.tracker-why p {
  color: #e30613;
  font-family: 'helveticaneuebold';
  text-decoration-style: 20px;
  margin-bottom: 0;
}

.tracker-level-title {
  margin-bottom: 50px;
}

.tracker-level-title * {
  font-family: 'helveticaneuebold';
  font-size: 30px;
  text-align: center;
  margin-bottom: 0;
}

.level-title {
  width: 100%;
  padding-bottom: 20px;
}

.level-hr {
  height: 1px;
  width: 100%;
  opacity: 1;
  color: #e30613;
  margin-bottom: 0;
  margin-top: 0;
}

.level-title h1 {
  font-family: 'helveticaneuebold';
  font-size: 20px;
  padding-left: 10px;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.level-number {
  font-family: 'helveticaneuebold';
  font-size: 30px;
}

.level-excerpt {
  margin-top: 30px;
}

.level-excerpt p {
  margin-bottom: 0;
}

.levels-row {
  margin-bottom: 90px;
}

.thumbnail-tracker {
  cursor: pointer;
}

.thumbnail-tracker img {
  object-fit: cover;
}

.half-overlay {
  width: 50% !important;
  height: 100% !important;
  left: 50% !important;
  top: 0 !important;
  background-color: rgba(0, 55, 95, 0.7);
  z-index: 1;
}

.play-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-btn img {
  position: relative;
  z-index: 2;
}

.video-row {
  margin-bottom: 90px;
}

.search-list-content {
  padding: 20px;
}

.search-list-content p {
  margin-bottom: 0;
}

.search-by-title {
  margin-bottom: 30px;
}

.search-by-title h1 {
  text-align: center;
  font-family: 'helveticaneuebold';
  font-size: 30px;
  margin-bottom: 0;
}

.icon-search {
  border-radius: 50%;
  padding: 25px;
  margin-bottom: 20px;
}

.icon-search img {
  height: 100px;
  width: 100px;
}

.search-title {
  margin-bottom: 20px;
}

.search-title h1 {
  font-family: 'helveticaneuebold';
  font-size: 20px;
  text-align: center;
  margin-bottom: 0;
}

.search-excerpt p {
  text-align: center;
}

.search-by {
  margin-bottom: 30px;
}

.limitations-title h1 {
  font-family: 'helveticaneuebold';
  font-style: italic;
  font-size: 20px;
  text-align: center;
  margin-bottom: 15px;
}

.limitations-subtitle p {
  font-family: 'helveticaneueitalic';
  text-align: center;
  margin-bottom: 0;
}

.limitations-row {
  margin-bottom: 90px;
}

.icon-stake {
  border-radius: 50%;
  padding: 20px;
  margin-bottom: 20px;
}

.icon-stake img {
  height: 100px;
  width: 100px;
}

.stake-title h1 {
  font-family: 'helveticaneuebold';
  font-size: 20px;
  text-align: center;
  margin-bottom: 0;
}

.stakeholders-row {
  margin-bottom: 70px;
}

.level-col {
  margin-bottom: 20px;
}

.search-col {
  margin-bottom: 20px;
}

.stake-col {
  margin-bottom: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-right: 10px;
  margin-left: 10px;
}

.home-banner-wrapper {
  margin-bottom: 262px;
}

.home-banner-content {
  height: 600px;
  max-height: 600px;
}

.home-video-container {
  z-index: -1;
  width: 100%;
}

.home-video {
  height: 600px;
  width: 67%;
  object-fit: cover;
}

.home-banner-desc {
  margin-top: 90px;
  background-color: #00375F;
  padding: 30px;
}

.home-banner-title h1 {
  font-family: 'helveticaneuebold';
  font-size: 25px;
  color: #fff;
  margin-bottom: 16px;
}

.home-banner-excerpt p {
  color: #fff;
  margin-bottom: 0;
}

.home-about-img img {
  width: 100%;
  min-height: 550px;
  object-fit: cover;
}

.home-about-title h1 {
  font-family: 'helveticaneuebold';
  font-size: 30px;
  margin-bottom: 50px;
  margin-top: 50px;
}

.home-about-excerpt p {
  margin-bottom: 50px;
  line-height: 1.1;
  text-align: justify;
}



.home-about-description {
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.swiper-icon-home img {
  height: 100px;
  width: 100px;
}

.swiper-icon-home {
  border-radius: 50%;
  padding: 20px;
  margin-bottom: 20px;
}

.swiper-element {
  background-color: #fff;
  padding: 20px;
  /* margin-right: 50px; */
}

.home-swiper {
  margin-right: 40px !important;
}

.home-swiper-container {
  transform: translateY(-50%);
}

.home-swiper-container .swiper-slide {
  padding: 20px;
}

.home-swiper .swiper-slide {
  padding-right: 15px;
  padding-left: 15px;
}

.swiper-button-prev1.home {
  left: -40px !important;
}

.swiper-button-next1.home {
  right: 0 !important;
}

.home-sections {
  padding: 20px;
  margin-bottom: 20px;
  flex-direction: column;
}

a.read-more {
  font-size: 14px;
  color: #1582C6;
}

.swiper-title-home h1 {
  font-family: 'helveticaneuebold';
  font-size: 20px;
  margin-bottom: 20px;
}

.swiper-excerpt-home p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-bottom: 20px;
}

.home-about-section {
  margin-bottom: 180px;
}

.methods-wrapper {
  min-height: 500px;
}

.methods-image {
  height: 100%;
  width: 100%;
}

.methods-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.home-methods-overlay {
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(60, 160, 222, 0.6);
}

.methods-home {
  margin-bottom: 180px;
}

.method-content {
  z-index: 3;
  position: relative;
  background-color: #00375F;
  padding-right: 20px;
  padding-left: 20px;
}

.method-title h1 {
  color: #fff;
  font-family: 'helveticaneuebold';
  font-size: 30px;
  padding-top: 50px;
  margin-bottom: 15px;
}

.method-description * {
  color: #fff;
  padding-bottom: 50px;
  margin-bottom: 0;
  line-height: 1.2;
}

.method-description h1 {
  color: #fff;
  padding-bottom: 15px;
  font-family: 'helveticaneuebold';
  font-size: 20px;
}

.method-description h2 {
  color: #fff;
  padding-bottom: 15px;
  font-family: 'helveticaneuebold';
  font-size: 20px;
}

.method-description h3 {
  color: #fff;
  padding-bottom: 15px;
  font-family: 'helveticaneuebold';
  font-size: 20px;
}

.method-description h4 {
  color: #fff;
  padding-bottom: 15px;
  font-family: 'helveticaneuebold';
  font-size: 20px;
}

.method-description h5 {
  color: #fff;
  padding-bottom: 15px;
  font-family: 'helveticaneuebold';
  font-size: 20px;
}

.method-description h6 {
  color: #fff;
  padding-bottom: 15px;
  font-family: 'helveticaneuebold';
  font-size: 20px;
}

.read-more.methods {
  padding-bottom: 50px;
}

.method-content.one {
  transform: translateY(-90px);
}

.method-content.two {
  transform: translateY(90px);
}

.providers-wrapper {
  min-height: 500px;
}

.providers-image {
  height: 100%;
  width: 100%;
}

.providers-image img {
  height: 100%;
  width: 60%;
  object-fit: cover;
}

.providers-content {
  margin-top: 50px;
  z-index: 1;
  position: relative;
  background-color: rgba(0, 55, 95, 0.5);
  padding: 0 40px;
}

.providers-title h1 {
  color: #fff;
  font-family: 'helveticaneuebold';
  font-size: 30px;
  padding-top: 50px;
  margin-bottom: 0;
  padding-bottom: 50px;
}

.providers-desc p {
  color: #fff;
  padding-bottom: 110px;
}

.providers-home {
  margin-bottom: 100px;
}

.providers-row {
  bottom: 0;
  transform: translateY(50%);
  padding-left: 10px;
  padding-right: 10px;
}

.providers-row img {
  max-width: 100%;
  object-fit: cover;
}

.providers-swiper {
  transform: translateY(-50%);
}

.provider-img-swiper {
  width: 100%;
  max-width: 100%;
}

.providers-mobile img {
  object-fit: contain;
  width: 100%;
  background-color: #fff;
  padding: 5px;
}

.sectors-wrapper-home {
  min-height: 650px;
}

.home-sector-image {
  height: 100%;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(0, 55, 95, 0.6);
}

.home-sectors-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 55, 95, 0.8);
  z-index: 1;
}

.sectors-container-home {
  z-index: 5;
}

.sectors-home-title {
  z-index: 5;
  position: relative;
  margin-top: 50px;
}

.sectors-home-title h1 {
  color: #fff;
  font-family: 'helveticaneuebold';
  font-size: 30px;
  margin-bottom: 50px;
  text-align: center;
}

.btn-container.home {
  margin-bottom: 50px;
}

.home-reports {
  margin-top: 90px;
  margin-bottom: -58px;
}

.home-reports-row.swiper {
  transform: translateY(-200px);
}

.about-read-more {
  bottom: -5px;
}

.provider-wrapper {
  transform: translate(-50%, -50%);
  background-color: white;
  width: 100%;
}

.provider-image {
  float: left;
  max-height: 200px;
  margin-right: 10px;

}

.provider-image img {
  height: 146px;
}

.provider-popup-title {
  display: inline-block;
  font-family: 'helveticaneuebold';
  font-size: 20px;
}

.provider-content {
  background-color: #fff;
  padding: 30px 30px 56px 30px;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.provider-description p {
  margin-bottom: 0;
}

.provider-popup {
  background-color: rgba(0, 55, 95, 0.5);
  z-index: 3000000000;
}

.react-datepicker__input-container input {
  height: 40px;
  border: 1px solid #00375F;
  cursor: pointer;
  background-image: url(/public/assets/images/arrow-down-sign-to-navigate.svg);
  background-repeat: no-repeat;
  background-position-x: calc(100% - 14px);
  background-position-y: 46%;
  border-color: #00375F;
}

.react-datepicker__input-container input::placeholder {
  font-family: 'helveticaneuemedium';
  color: #00375F;
  padding-left: 10px;
}

.subscribed {
  margin-top: 5px;
  color: #fff;
}

.providers-row.swiperr {
  transform: translateY(100%);
}

.providers-row.mobile-swiperr {
  transform: translateY(-30%);
  padding-left: 10px;
  padding-right: 10px;
}

.view-all-reports {
  right: 160px;
  top: 58%;
}

.view-all-reports:hover {
  text-decoration: underline;
}

.view-all-reports-mobile {
  text-align: center;
  transform: translateY(-72px);
  z-index: 15;
  position: relative;
}

.view-all-reports-mobile:hover {
  text-decoration: underline;
}

.method-col {
  z-index: 1;
}

.method-description p {
  text-align: justify;
}

.providers-desc p {
  text-align: justify;
}

.provider-icon-col img {
  width: 100%;
  background-color: #fff;
  padding: 5px;
  object-fit: contain;
}

.provider-icon-col {
  cursor: pointer;
}

.prov-content {
  background-color: rgba(0, 55, 95, 0.5);
  padding-right: 20px;
  padding-left: 20px;
}

.sectors-link {
  z-index: 2;
  position: relative;
}

.provider-image img {
  object-fit: cover;
}

.tracker-title h1{
  font-family: 'helveticaneuebold';
  font-size: 30px;
  text-align: center;
  padding-top: 90px;
  padding-bottom: 50px;
}

.tracker-description{
  margin-bottom: 50px;
}

.tracker-description *{
  padding: 0;
  margin: 0;
  text-align: justify;
  font-size: 16px;
}

.generate-report-title h1{
  font-family: 'helveticaneuebold';
  font-size: 30px;
  margin-bottom: 50px;
}

.generate-report{
  margin-bottom: 90px;
}








@media (hover: hover) {
  .url-loc:hover p {
    color: red;
  }

  .view-btn:hover {
    background-color: #fff;
    color: #00375F;
  }

  .next-survey:hover {
    background-color: #fff;
  }

  .next-survey:hover img {
    filter: invert(16%) sepia(19%) saturate(17085%) hue-rotate(182deg) brightness(50%) contrast(103%);
  }

  .prev-survey:hover {
    background-color: #fff;
  }

  .prev-survey:hover img {
    filter: invert(16%) sepia(19%) saturate(17085%) hue-rotate(182deg) brightness(50%) contrast(103%);
  }

  .view-btn.reverted:hover {
    color: #fff;
    background-color: #00375f;
  }

  .read-more:hover p {
    text-decoration: underline;
  }

  .search-btn:hover {
    background-color: #00375F;
    color: #fff;
  }

  a.read-more:hover {
    text-decoration: underline !important;
    color: #1582C6 !important;
  }

  .article-title a:hover {
    color: inherit;
  }
}










/* media queries */

@media screen and (min-width: 1200.1px) {
  .sectors-col {
    margin-bottom: 55px;
  }
}

@media screen and (min-width: 1659px) {
  .blue-sector-div {
    width: 30% !important;
  }

  .single-sector-left-section {
    width: 28% !important;
  }
}

@media screen and (min-width: 768.1px) {
  .custom-ratio-hover {
    --bs-aspect-ratio: 30%;
  }
}

@media screen and (max-width: 1399.9px) {
  .sectoral-categories {
    padding: 60px 43px;
  }

  .logo-sector-container {
    right: 10px;
  }

  .single-sector-description p {
    margin-right: 50px;
  }

  .swiper-col .swiper-button-next {
    right: -10px !important;

  }

  .swiper-col .swiper-button-prev {
    left: -10px !important;
  }

  .swiper-col .swiper-button-next {
    right: -55px !important;

  }

  .swiper-col .swiper-button-prev {
    left: -55px !important;

  }
}

@media screen and (max-width: 1199.9px) {
  .blue-sector-div {
    position: relative;

    margin-bottom: 55px;
  }

  .logo-sector-container {
    top: 30px;
    right: 60px;
  }

  .single-sector-section {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .sectors-col:not(:nth-child(1), :nth-child(2)) {
    margin-top: 55px !important;
  }

  .article-swiper .swiper-button-next {
    right: -5px;
  }

  .article-swiper .swiper-button-prev {
    left: -5px;
  }



  section.contact-us {
    height: 450px;
    min-height: 450px;
    margin-bottom: 90px;
  }

  .map-contact {
    width: 450px;
    height: 450px;
  }

  .contact-form {
    margin-bottom: 13px;
  }

  .swiper-col {
    margin-left: 90px;
  }

  .articlesSwiper {
    width: 90%;
  }

  .swiper-button-next1 {
    right: -18px !important;
  }

  .swiper-button-prev1 {
    left: -18px !important;
  }

  .stake-col {
    margin-bottom: 20px;
  }



  .home-sectors-row {
    margin-bottom: 50px;
  }


  .home-reports {
    margin-bottom: -28px;
  }

}

@media screen and (max-width: 991.9px) {
  .trans:not(:last-child) {
    padding-bottom: 24px;
  }

  .filter-col.gal {
    padding-bottom: 10px;
  }

  .clear-all-label.gallery {
    padding-bottom: 20px;
  }

  .mySwiper {
    padding-top: 0 !important;
  }

  .conditions-row h2:first-child {
    padding-top: 30px;
  }

  .privacy-subtitle h1 {
    padding-top: 30px;
  }

  .terms-row {
    padding-top: 25px;
  }

  .about-image-col {
    margin-bottom: 20px;
  }

  .footer-hr {
    margin-top: 24px !important;
  }

  .letter-wrapper button {
    color: #00375f;
    background-color: #fff;
  }

  .designed-by {
    padding-top: 20px;
  }

  .about-image-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .terms-content {
    width: 700px;
    height: 80vh;
  }

  .terms-title {
    font-size: 16px;
  }

  .research:nth-child(1) {
    margin-top: 0;
  }

  .research:nth-child(3) {
    margin-top: 0;
  }

  .research:not(:last-child) {
    margin-bottom: 20px;
  }

  .partners-content {
    opacity: 1;
  }

  .partners-col {
    margin-bottom: 20px;
  }

  .mobile-partners-content {
    padding: 20px;
  }

  .mobile-partners-content img {
    /* border: #00375f solid 1px; */
    margin-bottom: 20px;
  }

  .partners {
    padding-bottom: 90px;
  }

  .partners-content .partners-excerpt-mobile {
    opacity: 1;
  }

  .shadow-mobile {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .accept-btn-container {
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .next-btn-container {
    margin-bottom: 20px !important;
  }

  .ratio-single-sector {
    --bs-aspect-ratio: 50%;
  }

  .single-sector-image-col {
    margin: 0;
    padding-right: 12px !important;
    margin-bottom: 25px;
  }

  .single-sector-description p {
    margin-right: 0 !important;
  }

  .sub {
    margin-bottom: 20px;
    margin-left: 0;
    margin-right: 0;
  }

  .single-sector-left-section,
  .single-sector-right-section {
    height: 40%;
    /* width: 100px !important;s */
  }

  .single-sector-description {
    margin-right: 0;

  }

  .remove-pad {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .sub-sectors {
    margin-bottom: 46px;
  }

  .clear-all-label {
    padding-top: 0;
  }

  .map-container {
    position: relative;
    width: 100%;
  }

  section.contact-us {
    height: auto;
    margin-bottom: 15px;
  }

  .contact-form {
    margin-bottom: 0;
  }

  .banner-image {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 500px;
  }

  .banner-image-img {
    width: 100%;
  }

  .banner-content {
    height: auto;
    max-height: fit-content;
  }

  .get-in-touch-container {
    transform: translateY(-50%);
    margin-bottom: -42px;
  }

  section.contact-banner {
    padding-bottom: 0;
  }

  .contact-container {
    transform: translateY(-75px);
  }

  .contact-input.message {
    margin-bottom: 0;
  }

  .swiper-col {
    margin-bottom: 105px;
  }

  .featured-articles-content {
    margin-bottom: 50px;
  }

  .pad-bot {
    padding-bottom: 90px;
  }

  .clear-all-label.gallery {
    margin-top: 20px;
  }

  .map-contact {
    width: 100%;
  }

  .contact-us-col {
    margin-bottom: 50px;
  }

  .swiper-col {
    margin-left: 0;
  }

  .mySwiper {
    width: 85%;
  }

  .swiper-col .swiper-button-next {
    right: -8px !important;
  }

  .swiper-col .swiper-button-prev {
    left: -8px !important;
  }



  .article-swiper {
    margin-left: 0;
  }

  .swiper-button-next1 {
    right: -15px !important;
  }

  .swiper-button-prev1 {
    left: -15px !important;
  }

  .search-form {
    margin-bottom: 10px;
  }

  .home-video {
    width: 100%;
  }

  .home-banner-desc {
    margin: 0;
    margin-right: 12px;
    position: absolute;
    bottom: -180px;
  }

  .home-banner-wrapper {
    margin-bottom: 210px;
  }

  .home-swiper-container {
    transform: translateY(0%);
  }

  .home-swiper {
    margin-right: auto !important;
    width: 95%;
  }

  .swiper-button-next1.home {
    right: -15px !important;
  }

  .swiper-button-prev1.home {
    left: -15px !important;
  }

  .home-about-section {
    margin-top: 90px;
  }

  .method-content.one {
    transform: translate(0);
  }

  .method-content.two {
    transform: translate(0);
  }

  .method-content {
    margin-bottom: 50px;
  }

  .method-content.kll {
    margin-top: 50px;
  }

  .method-title h1 {
    margin-bottom: 30px;
  }

  .method-description p {
    padding-bottom: 30px;
  }

  .read-more.methods {
    padding-bottom: 30px;
  }

  .method-description.tracker-desc p {
    padding-bottom: 10px !important;
  }

  .providers-image img {
    width: 100%;
  }

  .providers-content {
    margin-top: 0;
    transform: translateY(-80px);
  }

  .providers-title h1 {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .providers-desc p {
    padding-bottom: 90px;
  }

  .providers-row {
    transform: translateY(-80%);
    z-index: 5;
    position: relative;
  }

  .providers-row img {
    z-index: 5;
  }

  .methods-home {
    margin-bottom: 90px;
  }

  .providers-home {
    margin-bottom: -38px;
  }

  .home-about-section {
    margin-bottom: 90px;
  }

  .providers-content {
    background-color: transparent;
  }

  .view-all-reports {
    right: 60px;
  }

  .home-reports {
    margin-bottom: -48px;
  }

  .provider-content {
    max-height: 50vh;
    overflow-y: scroll;
  }

  .provider-image img {
    height: 140px;
  }

}

@media (max-width: 991.9px) and (min-width: 767.9px) {
  .col-letter {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .footer-content-container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

@media screen and (max-width: 767.9px) {
  .turn-absolute {
    position: absolute;
    top: 54px;
    right: 0;
  }

  .about-earrel-content {
    width: 90%;
  }

  .terms-content {
    width: 540px;
    height: 55vh;
  }

  .research-description.shadow-mobile {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .video-map {
    margin-bottom: 20px;
  }

  .map-title h1 {
    margin-bottom: 30px;
  }

  .map-description p {
    font-size: 14px;
  }

  .map-title h1 {
    font-size: 20px;
  }

  /* .beirut-map .container {
    max-width: fit-content;
  } */

  .map-video video {
    height: 100%;
  }

  .shadow-mobile {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .social-media {
    padding-bottom: 20px;
  }

  .designed-by {
    padding-top: 4px !important;
  }

  .add-pad {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .middle-lamp {
    display: none !important;
  }

  .lamps-row {
    justify-content: space-between !important;
  }

  .single-sector-top-section {
    height: calc(258px - 30px);
  }

  .featured-col {
    margin-bottom: 20px;
  }

  .articlesSwiper {
    width: 85%;
  }

  .swiper-button-next1 {
    right: -10px !important;
  }

  .swiper-button-prev1 {
    left: -10px !important;
  }



  .tracker-level-title h1 {
    font-size: 25px;
  }

  .home-banner-desc {
    bottom: -200px;
  }

  .sections-container {
    padding-top: 40px;
  }

  .home-about-img {
    margin-bottom: 20px;
  }

  .home-about-title h1 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .home-about-excerpt p {
    line-height: 1.3;
    margin-bottom: 30px;
  }

  div.read-more {
    margin-top: 30px;
  }

  .providers-mobile {
    display: flex;
    justify-content: center;
  }

  .providers-row {
    transform: translateY(-160px);
  }

  .providers-content {
    padding: 20px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .providers-home {
    margin-bottom: -70px;
  }

  .home-sections {
    flex-direction: row !important;
  }

  .swiper-icon-home {
    margin-right: 20px;
  }

  .swiper-icon-home img {
    height: 70px;
    width: 70px;

  }

  .view-all-reports {
    right: 20px;
  }

  .stake-col {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .react-datepicker__input-container input {
    width: 100%;
  }

  .provider-content {
    max-height: 60vh;
  }

  .provider-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}

@media screen and (max-width: 575.9px) {
  .turn-absolute {
    width: 90%;
    top: 30px !important;
  }

  .swiper-reports-container {
    margin-bottom: -166px;
  }

  .featured-articles {
    margin-bottom: 90px;
  }

  .light {
    width: 75%;
  }

  .images-container {
    padding-top: 30px;
  }

  .terms-content {
    height: 85vh;
  }

  .logo-404 {
    left: 20px;
  }

  .turn-absolute img {
    margin-left: 20px;
  }

  .about-earrel-content {
    width: 90%;
    padding: 20px;
  }

  .about-erreal-excerpt p {
    font-size: 14px;
  }

  .shadow-mobile {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .partners-col {
    margin-bottom: 20px;
  }

  .mobile-partners-content {
    padding: 20px;
  }

  .mobile-partners-content img {
    /* border: #00375f solid 1px; */
    margin-bottom: 20px;
  }

  .about-lta .row {
    padding-left: 12px;
    padding-right: 12px;
  }

  section.partners {
    padding-bottom: 90px;
  }

  .partners-content {
    opacity: 1;
  }

  .title-404 h1 {
    font-size: 130px;
  }

  .page-not-found p {
    font-size: 32px;
  }

  .request-not-found p {
    text-align: center;
    font-size: 18px;
  }

  .map-description p {
    font-size: 16px;
  }

  .map-title h1 {
    font-size: 30px;
  }

  .map-video {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .terms-title {
    font-size: 14px;
  }

  .accept-button {
    width: 110px;
    font-size: 14px;
  }

  .next-button {
    font-size: 14px;
  }

  .buttons-container {
    padding-right: 40px;
  }

  .lamps-row {
    justify-content: center !important;
  }

  .first-lamp {
    display: none !important;
  }

  .faqs-section {
    margin-right: 12px;
    margin-left: 12px;
  }

  .sector-section-title h1 {
    font-size: 26px;
  }

  .logo-sector-container {
    /* top: 35px; */
    right: 25px;
  }

  .sectoral-categories {
    padding: 40px 35px;
  }

  .sub-sectors {
    margin-right: 12px;
    margin-left: 12px;
  }

  .terms-col {
    margin-bottom: 10px;
  }

  .terms-privacy-container {
    margin-right: 12px;
    margin-left: 12px;
  }

  .single-sector-top-section {
    height: calc(208px - 30px);
  }

  .sectors-col:not(:nth-child(1)) {
    margin-top: 55px !important;
  }

  .page-div {
    height: 30px;
    width: 30px;
  }

  .next-arrow,
  .prev-arrow {
    width: 30px;
    height: 30px;
  }

  .survey-image {
    margin-bottom: 10px;
  }

  .get-in-touch-title h1 {
    text-align: center;
    font-size: 27px;
  }

  .location-text p {
    font-size: 14px;
  }

  .swiper-col .swiper-button-next {
    right: 0px !important;

  }


  .swiper-col .swiper-button-prev {
    left: 0px !important;
  }

  .mySwiper {
    padding: 25px !important;
  }

  .article-swiper .swiper-button-prev {
    left: 0;
  }

  .article-swiper .swiper-button-next {
    right: 0;
  }

  .survey-img {
    margin-bottom: 20px;
  }

  .swiper-button-prev1 {
    left: 20% !important;
    top: 98%;
  }

  .swiper-button-next1 {
    right: 20% !important;
    top: 98%;
  }

  .swiper-col .swiper-button-next {
    right: 20% !important;
    top: 98%;
  }

  .swiper-col .swiper-button-prev {
    left: 20% !important;
    top: 98%;
  }


  .articlesSwiper {
    width: 100%;
  }

  .single-article-excerpt h1 {
    font-size: 25px;
  }

  .home-banner-title h1 {
    font-size: 20px;
  }

  .home-banner-desc {
    bottom: -160px;
    margin-right: 12px;
  }

  .providers-mobile img {
    width: 70%;
  }

  .sectors-wrapper-home {
    display: block !important;
  }

  .home-sectors-container {
    z-index: 5;
    position: relative;
  }

  .sections-container {
    padding-top: 0;
  }

  .providers-home {
    margin-bottom: -70px;
  }

  .sectors-wrapper-home {
    min-height: 450px;
  }

  .home-reports-excerpt p {
    margin-bottom: 202px;
  }

  .home-reports-title h1 {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .view-all-reports-mobile {
    margin-bottom: 66px;
  }

  .provider-content {
    max-height: 80vh;
  }

  .providers-row.mobile-swiperr {
    transform: translateY(10%);
  }

  .providers-mobile img{
    height: 240px;
  }
}

@media (max-height: 600px) {
  .terms-content {
    height: 85vh !important;
  }

  .privacy-content p {
    padding-top: 5px;
  }
}

@media (max-width: 430px) {
  .designed-col {
    width: 100% !important;
  }
}

@media screen and (max-width: 390px) {
  .terms-title {
    font-size: 12px;
  }

  .terms-title {
    transform: translateX(-10px);
  }
}

@media screen and (max-height: 640px) {
  .main-404 {
    /* height: 100%; */
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 410px) {
  .provider-image img {
    width: 140px;
    object-fit: contain;
  }
}